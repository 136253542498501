import TaskAssignedGroup from './TaskAssignedGroup.vue';
import { mapState } from 'vuex';
import axios from 'axios';
export default {
  props: {
    taskId: {
      type: Number,
      required: false
    }
  },
  data: () => ({
    taskAssigned: false,
    objects: [],
    users: [],
    groups: [],
    mode: ''
  }),
  methods: {
    open(users = [], groups = [], mode = 'add') {
      this.taskAssigned = true;
      this.users = users;
      this.groups = groups;
      this.mode = mode;
    },
    openTaskAssignedGroup(item) {
      this.$refs.group.open(item);
    },
    async updateAssignees() {
      if (this.mode == 'add') {
        await axios.patch(`/v1/task/${this.taskId}/assignees`, {
          assignedUsers: this.users,
          assignedWorkGroups: this.groups
        }).then(response => {
          this.users = response.data.assignedUsers;
          this.groups = response.data.assignedWorkGroups;
          this.$emit('saved', this.users, this.groups);
          this.$toast.success('Bearbeiter wurden gespeichert');
        }).catch(error => {
          console.log(error);
        });
      } else {
        this.$emit('saved', this.users, this.groups);
      }
      this.taskAssigned = false;
    },
    loadAssignables() {
      this.$store.dispatch('loadAssignableUsers');
      this.$store.dispatch('loadAssignableWorkGroups');
    }
  },
  mounted() {
    this.loadAssignables();
  },
  computed: {
    ...mapState({
      assignableUsers: state => state.task.assignableUsers,
      assignableWorkGroups: state => state.task.assignableWorkGroups
    })
  },
  components: {
    TaskAssignedGroup
  }
};